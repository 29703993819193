import { Button } from "Components/Atoms/Button";
import Typography from "Components/Atoms/Typography";
import React from "react";
import { Link } from "react-router-dom";
import { Flex } from "styles/layouts/Flex";
import { Wrapper, Container } from "./style";

interface HomeProps {}
const SubFooter: React.FC<HomeProps> = () => {
  return (
    <Wrapper>
      <Container>
        <div>
          <Typography as="h4" className="heading-1">
            Join Us Today!
          </Typography>
          <Typography as="p" className="paragraph-3 max-width-520 mt-10">
            Let’s make your products accessible to thousands of retailers and
            customers who uses our platform.
          </Typography>

          <Link to="/contact-us">
            <Button className="btn-primary btn-dark mt-50" label="Contact Us" />
          </Link>
        </div>
        <div>
          <Typography as="h4" className="heading-2 mb-40">
            Contact Info
          </Typography>

          <Flex gap="1rem" flexRow>
            <img src="/assets/images/envelope-icon.svg" alt="" />

            <Typography as="p" className="paragraph-3">
              <a
                href="mailto:support@24seventech.co"
                className="paragraph-3 text-decoration-none"
              >
                support@24seventech.co
              </a>
            </Typography>
          </Flex>
          <Flex gap="1rem" className="mt-25" flexRow>
            <img src="/assets/images/mobile-icon.svg" alt="" />
            <Typography as="p" className="paragraph-3">
              <a
                href="tel:+23408020001111"
                className="paragraph-3 text-decoration-none"
              >
                +234 (0) 802 000 1111
              </a>
            </Typography>
          </Flex>
          <Flex gap="1rem" className="mt-25" flexRowAiStart>
            <img src="/assets/images/location-icon.svg" alt="" />
            <Typography as="p" className="paragraph-3 max-width-220px">
              <a
                href="https://goo.gl/maps/7zoKJk3Z5CHfJvbQ6"
                className="paragraph-3 text-decoration-none"
              >
                42B Ijoko Road, Ogun State
              </a>
              <br />
              <a
                href="https://goo.gl/maps/SJE824EyRApVyvj49"
                className="paragraph-3 text-decoration-none"
              >
                Delaware, United State
              </a>
            </Typography>
          </Flex>
        </div>
      </Container>
    </Wrapper>
  );
};

export default SubFooter;
