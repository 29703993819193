import { css } from "styled-components";

// Grid variables
export const maxRowWidth = "140rem";
export const maxRowWidthExtended = "164rem";
export const centerExtendedSidePadding = "12rem";

export const Variables = css`
  :root {
    /* Color variables */
    --color-white: #ffffff;
    --color-black: #000000;

    --color-primary: #E34B31;

    --color-1: #3a3a3a;
    --color-2: #222222;
    --color-3: #4d4d4d;
    --color-4: #0b0b1b;
    --color-5: #1c1c52;
    --color-6: #f2333b;
    --color-7: #f4f4ff;
    --color-8: #1e1e1e;

    --center-extended-side-padding: 12rem;
  }
`;
