import React from "react";
import { Button } from "Components/Atoms/Button";
import Typography from "Components/Atoms/Typography";
import { WhatWeOfferSection, WhatWeOfferContainer } from "./style";
import { Link } from "react-router-dom";
// import Slider from "react-slick";

interface WhatWeOfferProps {}
const WhatWeOffer: React.FC<WhatWeOfferProps> = () => {
  // const settings = {
  //   className: "center",
  //   centerMode: true,
  //   infinite: true,
  //   centerPadding: "60px",
  //   slidesToShow: 3,
  //   autoplay: true,
  //   speed: 12000,
  //   autoplaySpeed: 1000,
  //   cssEase: "linear",
  //   // responsive: [
  //   //   {
  //   //     breakpoint: 900,
  //   //     settings: {
  //   //       slidesToShow: 1,
  //   //       slidesToScroll: 1,
  //   //       infinite: true,
  //   //       dots: true,
  //   //     },
  //   //   },
  //   // {
  //   //   breakpoint: 600,
  //   //   settings: {
  //   //     slidesToShow: 2,
  //   //     slidesToScroll: 2,
  //   //     initialSlide: 2,
  //   //   },
  //   // },
  //   // {
  //   //   breakpoint: 480,
  //   //   settings: {
  //   //     slidesToShow: 1,
  //   //     slidesToScroll: 1,
  //   //   },
  //   // },
  //   // ],
  // };
  const services = [
    {
      title: "Inventory Financing",
      desc: `We provide our thousands of Distributors and retailers within our 
      network with the Buy Now pay later (BNPL) model. 
      By providing a short-term financing to buy goods and pay 
      it off at later date helps our partners remain profitable and liquid `,
    },
    {
      title: "Data Analytics",
      desc: `Commerce is underpinned by a wealth of data , including marketplace stores, fulfillment 
      operation and delivery partners .Our data Analytics services are empowered by our unified data platform, 
      with integration to partner platform to provide insight and decision support to consumer brands, distributors 
      and retailers`,
    },
    {
      title: "Fulfillment",
      desc: `We understand the importance of fulfilling orders accurately and on-time. Our partners fulfillment service provides best in class service 
      levels for all orders from marketplaces.`,
    },
    {
      title: "Customer Service",
      desc: `24seven customer service is operating with the highest SLA beyond what is required by the team. 
      Blazing fast response time. All- day availability`,
    },
    {
      title: "Warehousing",
      desc: `Our networks of warehouses throughout the region combine the highest storage standard with flexible capacity to 
      meet the demands of the peak period.`,
    },
  ];
  return (
    <WhatWeOfferSection>
      <WhatWeOfferContainer>
        <div className="flex-shrink-0">
          <Typography as="h3" className="heading-7" text="What We Offer" />
          <Typography as="p" className="paragraph-3 max-width-400">
          End-to-End B2B solutions for leading global brands, distributors and retailers to deliver growth and efficiency, backed by well experienced teams and technology
          </Typography>
          <Button className="btn-primary btn-md mt-30" label="Contact Us" />
        </div>
        <div className="slider">
          <div className="slider-scroll">
            <div className="services">
              {/* <Slider {...settings}> */}
              {services.map((service, idx) => (
                <div key={idx} className={`service`}>
                  <div className="top">
                    <Typography
                      as="h4"
                      className="heading-11 mb-10"
                      text={service.title}
                    />
                    <Typography as="p" className="paragraph-7">
                      {service.desc}
                    </Typography>
                  </div>

                  <Link to="#" className="link">
                    Learn MORE
                  </Link>
                </div>
              ))}
              {/* </Slider>  */}
            </div>
          </div>
        </div>
      </WhatWeOfferContainer>
    </WhatWeOfferSection>
  );
};

export default WhatWeOffer;
