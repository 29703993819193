import React from "react";
import OurServicesTemplate from "Components/Templates/OurServices";

interface HomeProps {}
const OurServices: React.FC<HomeProps> = () => {
  return (
    <>
      <OurServicesTemplate />
    </>
  );
};

export default OurServices;
