import React from "react";
import TopNav from "Components/Molecules/TopNav";
import SubFooter from "Components/Organisms/SubFooter";
import Footer from "Components/Organisms/Footer";
import Header from "Components/Molecules/Header";
import { Center } from "styles/layouts/Center";
import { Flex } from "styles/layouts/Flex";
import Typography from "Components/Atoms/Typography";
import { Wrapper } from "./style";
import TeamCard from "Components/Molecules/TeamCard";

interface HomeProps {}

const AboutUsTemplate: React.FC<HomeProps> = () => {
  
  return (
    <Wrapper>
      <TopNav />
      <Header
        title={"Our Mission"}
        subTitle={
          "To build an interconnected ecosystem, with intertwined components, in order to deliver  unmatched customer experience across the African informal grocery retail value chain. "
        }
      />
      <section className="section-description">
        <Center>
          <Flex gap="3rem" className="layout" flexRowJcBetween>
            <div className="layout-image left">
              <img src="/assets/images/abt.jpeg" alt="" className="responsive-image" />
            </div>
            <div className="right">
              <Typography as="h3" className="heading-7 max-width-650 mb-25">
                THE MOST VALUED GLOBAL COMMERCE SCALE PARTNER IN AFRICA
              </Typography>
              <Typography as="p" className="paragraph-1 mb-10">
                Since the beginning, we have always been passionate about SME
                sustainability and profitability for the future. This we’ve been
                able to achieve by understanding the drivers for growth;
                designing an environment to build a career for our people,
                entrepreneurship and Transparent innovations are the key factors
                towards our success.
              </Typography>
              <Typography as="p" className="paragraph-1 mb-10">
                We are a Fasting growing company with a global perspective.
              </Typography>
              <Typography as="p" className="paragraph-1">
                The Africa retail space is a very vibrant industry but with lots
                of untapped value. In the nearest future, we aim to be a key
                player as a leading enabler in the commerce ecosystem throughout
                the continent.
              </Typography>

              <Flex gap="4.2rem" className="mt-50 details" flexRowAiCenter>
                <Typography
                  as="h5"
                  className="heading-2 max-width-650 display-flex-aicenter-gap-8"
                >
                  4200 <span className="heading-2--sub">No of Retailers</span>
                </Typography>
                <Typography
                  as="h5"
                  className="heading-2 max-width-650 display-flex-aicenter-gap-8"
                >
                  90% <span className="heading-2--sub">Female Retailers</span>
                </Typography>
                <Typography
                  as="h5"
                  className="heading-2 max-width-650 display-flex-aicenter-gap-8"
                >
                  200+ <span className="heading-2--sub">Consumer Brands</span>
                </Typography>
                <Typography
                  as="h5"
                  className="heading-2 max-width-650 display-flex-aicenter-gap-8"
                >
                  $5mn <span className="heading-2--sub">Recorded Sales</span>
                </Typography>
              </Flex>
            </div>
          </Flex>
        </Center>
      </section>
      <section className="section-team">
        <Center>
          <Typography as="h3" className="heading-7">
            Leadership
          </Typography>
          <Typography as="p" className="paragraph-3 mb-40">
            Our Management team has extensive global experience from some of the
            biggest names in consumer brand, retail, Logistic , Tech and
            Consulting.
          </Typography>
          <Flex gap="4rem" className='team-members' flexRowAiCenter>
            <TeamCard 
              imgUrl="leadership/femi.jpeg" 
              name="Olufemi Idowu" 
              title="Cofounder, Vision & Growth"
              profileLink="https://www.linkedin.com/in/olufemi-idowu-b64766a0" />
            <TeamCard 
              imgUrl="leadership/kenechukwu.jpeg" 
              name="Kenechukwu Udeh" 
              title="Cofounder, Strategy"
              profileLink="https://www.linkedin.com/in/kenechukwu-udeh-ab3650b8" />
          </Flex>
        </Center>
      </section>
      <SubFooter />
      <Footer />
    </Wrapper>
  );
};

export default AboutUsTemplate;
