import Typography from "Components/Atoms/Typography";
import React from "react";
import { Flex } from "styles/layouts/Flex";
import Wrapper from "./style";

interface TeamCardProps {
  imgUrl: string;
  name: string;
  title: string;
  profileLink: string;

}
const TeamCard: React.FC<TeamCardProps> = (props) => {

  const {imgUrl,name,title,profileLink} = props;
  return (
    <Wrapper>
      <img
        src={`/assets/images/${imgUrl}`}
        alt=""
        className="responsive-image"
      />
      <div className="details">
        <Typography as="h5" className="heading-9">
          {name}
        </Typography>
        <Typography as="h5" className="heading-10 mt-10">
          {title}
        </Typography>
        <br />
        <Flex gap="2rem">
          <a href={profileLink} target="_blank">
            <img src="/assets/images/linkedin-new.svg" alt="" />
          </a>
        </Flex>
       
      </div>
    </Wrapper>
  );
};

export default TeamCard;
