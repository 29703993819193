import React from "react";
import { Link } from "react-router-dom";
import { Wrapper } from "./style";

interface HomeLinkProps {}

const HomeLink: React.FC<HomeLinkProps> = () => {
  return (
    <Link to="/">
      <Wrapper>
        <img src="/assets/images/logo.svg" alt="" width={200} />
      </Wrapper>
    </Link>
  );
};

export default HomeLink;
