import styled from "styled-components";
import { responsive } from "styles/Abstract/Breakpoints";
import { FlexRowJcBetween } from "styles/Abstract/Mixins";
import { Center, CenterExtended } from "styles/layouts/Center";

export const Wrapper = styled.header``;

export const BigContainer = styled(CenterExtended)`
  padding-top: 15rem;
  padding-bottom: 15rem;
  background-color: var(--color-5);
  background-image: url("/assets/images/bg-img.svg");
  background-size: cover;
  background-position: bottom right;
  background-repeat: no-repeat;

  ${responsive(
    "sm",
    `
    padding-top: 10rem;
  padding-bottom: 10rem;
  `
  )}
  ${responsive(
    "xsm",
    `
    padding-top: 5rem;
  padding-bottom: 5rem;
  `
  )}
`;

export const Container = styled(Center)`
  ${FlexRowJcBetween};
  gap: 3rem;

  ${responsive(
    "sm",
    `
   flex-direction: column;
  gap: 2rem;

  `
  )}
`;
