import React from "react";
import TopNav from "Components/Molecules/TopNav";
import Brands from "Components/Organisms/Brands";
import Hero from "Components/Organisms/Hero";
import BigCard from "Components/Organisms/BigCard";

import Typography from "Components/Atoms/Typography";
import { Center } from "styles/layouts/Center";
import { WhatDiffContainer, WhatDiffSection } from "./style";
import SubFooter from "Components/Organisms/SubFooter";
import Footer from "Components/Organisms/Footer";
import WhatWeOffer from "./WhatWeOffer";

interface HomeProps {}

const Home: React.FC<HomeProps> = () => {
  return (
    <>
      <TopNav />
      <Hero />
      <Brands />
      <Center className="">
        <BigCard />
      </Center>
      <Center className="">
        <Typography as="h4" className="heading-6">
          <i>
            To build an interconnected ecosystems, with intertwined components,
            in order to deliver an unmatched customer experience across the
            African informal retail value chain.
          </i>
        </Typography>
      </Center>
      {/* What we offer section */}
      <WhatWeOffer />
      {/* What Makes Us Different Section */}
      <WhatDiffSection>
        <WhatDiffContainer>
          <Typography as="h4" className="heading-1">
            What Makes Us Different
          </Typography>
          <Typography as="p" className="paragraph-3 max-width-700 mb-10 mt-10">
            Customizable Solutions to Meet your business needs
          </Typography>

          <div className="content">
            <div className="left">
              <div>
                <Typography as="h4" className="heading-2">
                  CONSUMER BRANDS
                </Typography>
                <Typography as="i" className="paragraph-3">
                  <Typography as="b">
                    "Grow your Brand through an enhanced ecommerce."
                  </Typography>
                  <br />
                </Typography>
                <Typography as="p" className="paragraph-3 max-width-700 mt-10">
                  Our services in performance marketing, data analytics and real time insights enable brands to grow market share against category competitors and deliver differentiated shopping experience
                  </Typography>
              </div>
              <div>
                <Typography as="h4" className="heading-2">
                  DISTRIBUTOR
                </Typography>
                <Typography as="i" className="paragraph-3">
                  <Typography as="b">
                    "Maximize potential for your brand portfolio."
                  </Typography>
                  <br />
                </Typography>
                <Typography as="p" className="paragraph-3 max-width-700 mt-10">
                  We collaborate to ensure ecommerce is fully integrated to
                  achieve a wider route to market both online and offline
                </Typography>
              </div>
              <div>
                <Typography as="h4" className="heading-2">
                  RETAILER
                </Typography>
                <Typography as="i" className="paragraph-3">
                  <Typography as="b">
                    "Enabling your omnichannel transformation."
                  </Typography>
                  <br />
                </Typography>
                <Typography as="p" className="paragraph-3 max-width-700 mt-10">
                  Face lifting of existing stores and augment your existing
                  strength in category management, product shelving, and
                  customer experience fulfillment capabilities.
                </Typography>
              </div>
              <div>
                <Typography as="h4" className="heading-2">
                  FULFILLMENT
                </Typography>
                <Typography as="i" className="paragraph-3">
                  <Typography as="b">
                    "Grow your logistics earning by 3x"
                  </Typography>
                  <br />
                </Typography>
                <Typography as="p" className="paragraph-3 max-width-700 mt-10">
                  With guaranteed trips; we increase earnings above the average
                  market size by providing discounted rates on fuel and asset
                  financing.
                </Typography>
              </div>
            </div>
            <div className="right width-full">
              <img
                src="/assets/images/pic-9.png"
                alt=""
                className="width-full"
              />
            </div>
          </div>
        </WhatDiffContainer>
      </WhatDiffSection>
      <SubFooter />
      <Footer />
    </>
  );
};

export default Home;
