import Typography from "Components/Atoms/Typography";
import React from "react";
import { Flex } from "styles/layouts/Flex";
import { Wrapper, Container } from "./style";

interface BigCardProps {}

const BigCard: React.FC<BigCardProps> = () => {
  return (
    <Wrapper>
      <Container>
        <Typography as="h4" className="heading-3 left custom-font">
        Simplified process for unmatched customer experience
        </Typography>
        <div className="right">

          <Typography as="p" className="paragraph-2">
          “Good  is not good enough” we provide you with easy-to-use customizable solutions at your fingertips.With just a click of a button, goods are delivered to you in minutes Beyond this, we cushion your working capital by financing your inventory.
          </Typography>

          <Flex className="mt-30" gap="2rem" flexRowAiCenter>
            <div className="user">
              <img
                src="/assets/images/leadership/femi.jpeg"
                alt=""
                className="responsive-image rounded-border"
              />
            </div>
            <div>
              <Typography as="h5" className="heading-4">
              Olufemi, 
              </Typography>
              <Typography as="h6" className="heading-5">
              Co-founder
              </Typography>
            </div>
          </Flex>

        </div>
      </Container>
    </Wrapper>
  );
};

export default BigCard;
