import React from "react";
import Typography from "Components/Atoms/Typography";
import { Flex } from "styles/layouts/Flex";
import { Wrapper, Container } from "./style";

interface HomeProps {}
const Footer: React.FC<HomeProps> = () => {
  return (
    <Wrapper>
      <Container>
        <div>
          <img src="/assets/images/logo.svg" alt="" width={200} />
          <Typography as="p" className="paragraph-4 mt-30">
            © 2023 24seven. All Rights Reserved.
          </Typography>
        </div>
        <div>
          <Typography as="h4" className="heading-8 color-white mb-30">
            Follow Us
          </Typography>

          <Flex gap="2rem" flexRowAiCenter>
            <a href="https://www.linkedin.com/company/24seven-co/" target="_blank"><img src="/assets/images/linkedin-new.svg" alt="" /></a>
            <img src="/assets/images/instagram.svg" alt="" />
            <img src="/assets/images/twitter.svg" alt="" />
            <img src="/assets/images/facebook.svg" alt="" />
            {/* <img src="/assets/images/youtube.svg" alt="" /> */}
          </Flex>
        </div>
      </Container>
    </Wrapper>
  );
};

export default Footer;
