import React from "react";
import { AnimatedText } from "./style";

interface AnimatTextProps {
  label: string;
}
const AnimateText: React.FC<AnimatTextProps> = ({ label }) => {
  return (
    <AnimatedText
      initial={{ opacity: 0, y: 60 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -60 }}
      transition={{ duration: 1, ease: 'easeOut' }}
      className="color-primary"
    >
      {label}
    </AnimatedText>
  );
};

export default AnimateText;
