import { motion } from "framer-motion";
import styled from "styled-components";
import { responsive } from "styles/Abstract/Breakpoints";
import { FlexRowJcBetween } from "styles/Abstract/Mixins";
import { Center } from "styles/layouts/Center";

export const Wrapper = styled(motion.main)`
  .section-description {
    padding-top: 10rem;
    padding-bottom: 10rem;

    .layout {
      ${responsive(
        "sm",
        `
        flex-direction: column;
        align-items: flex-start;
        gap: 2rem;
  `
      )}
    }

    .layout-image {
      height: 43rem;
      border-radius: 1.2rem;
      overflow: hidden;

      ${responsive(
        "sm",
        `
        height: 30rem;
  `
      )}
      ${responsive(
        "sm",
        `
        height: 20rem;
  `
      )}
    }
  }

  .servImage {
    /* border-radius: 100%; */
  }

  .services-text {
    ${responsive(
      "sm",
      `
       max-width 100%;
  `
    )}
  }

  .change-order {
    ${responsive(
      "sm",
      `
      order: -1;
  `
    )}
  }
`;

export const Container = styled(Center)`
  ${FlexRowJcBetween};
  gap: 3rem;
`;
