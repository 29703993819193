import { motion } from "framer-motion";
import styled from "styled-components";
import { responsive } from "styles/Abstract/Breakpoints";
import { FlexRowJcBetween } from "styles/Abstract/Mixins";
import { Center } from "styles/layouts/Center";

export const Wrapper = styled(motion.main)`
  .section-description {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
`;

export const Container = styled(Center)`
  ${FlexRowJcBetween};
  gap: 3rem;

  & > * {
    flex-basis: 50%;

    ${responsive(
      "sm",
      `
      flex-basis: auto;
      `
    )}
  }

  ${responsive(
    "sm",
    `
  flex-direction: column;
  gap: 6rem;
  `
  )}
`;
