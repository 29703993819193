import React from "react";
import TopNav from "Components/Molecules/TopNav";
import Footer from "Components/Organisms/Footer";
import Header from "Components/Molecules/Header";
import { Flex } from "styles/layouts/Flex";
import Typography from "Components/Atoms/Typography";
import { Wrapper, Container } from "./style";
import Input from "Components/Molecules/Input";
import Textarea from "Components/Molecules/Input/Textarea";
import { Button } from "Components/Atoms/Button";

interface HomeProps {}

const ContactUsTemplate: React.FC<HomeProps> = () => {
  return (
    <Wrapper>
      <TopNav />
      <Header title="Contact Us" subTitle=" " />
      <section className="section-description">
        <Container>
          <div>
            <Typography as="h4" className="heading-2 mb-25">
              We’d love to hear from you!
            </Typography>

            <Flex gap="1rem" flexRow>
              <img src="/assets/images/envelope-icon.svg" alt="" />
              <Typography as="p" className="paragraph-3">
                <a
                  href="mailto:support@24seventech.co"
                  className="paragraph-3 text-decoration-none"
                >
                  support@24seventech.co
                </a>
              </Typography>
            </Flex>
            <Flex gap="1rem" className="mt-25" flexRow>
              <img src="/assets/images/mobile-icon.svg" alt="" />
              <Typography as="p" className="paragraph-3">
                <a
                  href="tel:+23408020001111"
                  className="paragraph-3 text-decoration-none"
                >
                  +234 (0) 802 000 1111
                </a>
              </Typography>
            </Flex>
            <Flex gap="1rem" className="mt-25" flexRowAiStart>
              <img src="/assets/images/location-icon.svg" alt="" />
              <Typography as="p" className="paragraph-3 max-width-385">
                <a
                  href="https://goo.gl/maps/7zoKJk3Z5CHfJvbQ6"
                  className="paragraph-3 text-decoration-none"
                >
                  42B Ijoko Road, Ogun State
                </a>
                <br />
                <a
                  href="https://goo.gl/maps/SJE824EyRApVyvj49"
                  className="paragraph-3 text-decoration-none"
                >
                  Delaware, United State
                </a>
              </Typography>
            </Flex>
          </div>
          <div>
            <Input label="Name" placeholder="John Doe" required />
            <Input
              label="Email Address"
              placeholder="johndoe@gmail.com"
              required
            />
            <Textarea
              label="Send Us a Message"
              placeholder="How may we help you?"
              rows="9"
              required
            />
            <Button
              className="btn-primary display-block ml-auto mt-50"
              label="Send message"
            />
          </div>
        </Container>
      </section>
      <Footer />
    </Wrapper>
  );
};

export default ContactUsTemplate;
