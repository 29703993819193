import React from "react";
import { Wrapper, BigContainer, Container } from "./style";
import Typography from "Components/Atoms/Typography";

interface HeaderProps {
  title?: string;
  subTitle?: string;
}
const Header: React.FC<HeaderProps> = ({ title, subTitle }) => {
  return (
    <Wrapper>
      <BigContainer>
        <Container>
          <Typography as="h4" className="heading-3">
            {title ? title : "About Us"}
          </Typography>
          <Typography as="p" className="paragraph-5 max-width-590">
            {subTitle
              ? subTitle
              : `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rhoncus
            vitae elementum turpis mauris, imperdiet ipsum. Quis lorem pulvinar
            est volutpat eget quis sit mus accumsan. Integer elit vel,
            pellentesque posuere imperdiet. Sollicitudin orci, nunc massa risus
            semper vitae senectus proin risus. Elementum ullamcorper est, magna
            sed cumsan. Integer elit vel, pellentesque posuere imperdiet.
            Sollicitudin orci, nunc massa risus semper vitae senectus proin
            risus. Elementum ullamcorper est, .`}
          </Typography>
        </Container>
      </BigContainer>
    </Wrapper>
  );
};

export default Header;
