import React from "react";
import { ButtonStyle } from "./style";

interface ButtonProps {
  label?: any;
  className?: string;
  children?: JSX.Element;
  onClick?: () => void;
}
const Button: React.FC<ButtonProps> = (props) => {
  const { label, children } = props;
  return <ButtonStyle {...props}> {label || children} </ButtonStyle>;
};

export default Button;
