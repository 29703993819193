import styled from "styled-components";
import { FlexRowJcBetweenAiCenter } from "styles/Abstract/Mixins";
import { MoveXAxis } from "styles/Base/Animation";
import { Center } from "styles/layouts/Center";

export const Wrapper = styled.header`
  padding-top: 7rem;
  padding-bottom: 7rem;
`;

export const SlideWrapper = styled(Center)`
  overflow-x: hidden;
`;

export const Container = styled.div`
  ${FlexRowJcBetweenAiCenter};
  gap: 3rem;
  animation: ${MoveXAxis} 12s linear infinite alternate both;

  &:hover {
    animation-play-state: paused;
  }
`;
