import { motion } from "framer-motion";
import styled from "styled-components";
import { responsive } from "styles/Abstract/Breakpoints";
import { FlexRowJcBetween } from "styles/Abstract/Mixins";
import { Center } from "styles/layouts/Center";

export const Wrapper = styled(motion.main)`
  .section-description {
    padding-top: 10rem;
    padding-bottom: 10rem;

    .layout {
      .left {
        flex-basis: 50%;
        flex-shrink: 1;
      }
      .right {
        flex-basis: 50%;
        flex-shrink: 1;
      }
      ${responsive(
        "sm",
        `
        flex-direction: column;
        align-items: flex-start;
        gap: 2rem;
  `
      )}
    }

    .layout-image {
      max-height: 43rem;
      border-radius: 1.2rem;
      overflow: hidden;

      ${responsive(
        "sm",
        `
        max-height: 30rem;
  `
      )}
      ${responsive(
        "xsm",
        `
        height: 20rem;
  `
      )}
    }

    .details {
      flex-wrap: wrap;
    }
  }
  .section-team {
    padding-bottom: 10rem;

    .team-members{
      ${responsive('sm',`
      flex-direction: column;
      gap: 4rem;
      `)}
    }
  }

  .servImage {
    border-radius: 2%;
  }
`;

export const Container = styled(Center)`
  ${FlexRowJcBetween};
  gap: 3rem;
`;
