import HomeLink from "Components/Atoms/HomeLink";
import NavLink from "Components/Atoms/NavLink";
import React from "react";
import { Center } from "styles/layouts/Center";
import { Flex } from "styles/layouts/Flex";
import Wrapper from "./style";

interface TopNavProps {}

const TopNav: React.FC<TopNavProps> = () => {
  return (
    <Wrapper>
      <Center>
        <Flex gap="2rem" flexRowJcBetweenAiCenter>
          <HomeLink />
          <Flex gap="5rem" className="nav-links" flexRowAiCenter>
            <NavLink href="/our-services" label="Our Services" />
            <NavLink href="/about-us" label="About Us " />
            <NavLink href="/contact-us" label="Contact Us" />
          </Flex>
        </Flex>
      </Center>
    </Wrapper>
  );
};

export default TopNav;
