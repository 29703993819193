import { css } from "styled-components";

export const Typography = css`
  body {
    font-family: "Inter", Helvetica, Arial, serif;
    font-size: 1.6rem;
    line-height: 1.7rem;
    font-weight: 500;
  }

  /* Headings */
  .heading {
    &-1 {
      font-weight: 700;
      /* font-size: 5.6rem; */
      font-size: clamp(2rem, 3vw, 5.6rem);
      line-height: 100%;
      font-family: "Inter",Athelas, Helvetica, Arial, serif;
      color: var(--color-2);
    }
    &-2 {
      font-weight: 700;
      font-size: clamp(2rem, 3vw, 2.8rem);
      /* font-size: 2.8rem; */
      line-height: 3rem;
      font-family: "Inter",Athelas, Helvetica, Arial, serif;

      color: var(--color-2);
      vertical-align: middle;

      &--sub {
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 3rem;
        vertical-align: middle;
        color: var(--color-3);
      }
    }
    &-3 {
      font-weight: 700;
      font-size: clamp(2.4rem, 3vw, 4rem);
      /* font-size: 4rem; */
      line-height: 120%;
      color: var(--color-white);
    }
    &-4 {
      font-weight: 700;
      font-size: 16px;
      line-height: 160%;
      text-transform: uppercase;
      color: var(--color-white);
    }
    &-5 {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 160%;
      color: var(--color-white);
    }
    &-6 {
      font-weight: 400;
      font-size: clamp(2.1rem, 3vw, 3.8rem);
      /* font-size: 3.8rem; */
      line-height: 120%;
      color: #06032f80;
    }
    &-7 {
      font-weight: 700;
      font-size: clamp(2.2rem, 3vw, 4rem);
      /* font-size: 4rem; */
      line-height: 120%;
      color: var(--color-2);
    }
    &-8 {
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: var(--color-white);
    }
    &-9 {
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 160%;
      color: var(--color-white);
    }
    &-10 {
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 160%;
      color: var(--color-white);
    }
    &-11 {
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 3rem;
      color: var(--color-2);
    }
  }

  /* Paragraphs */
  .paragraph {
    &-1 {
      font-weight: 400;
      font-size: clamp(1.8rem, 3vw, 2rem);
      line-height: clamp(2.6rem, 3vw, 3rem);
      /* font-size: 2rem; */
      /* line-height: 3rem; */
      color: #06032f80;
    }
    &-2 {
      font-weight: 400;
      font-size: 1.9rem;
      line-height: 3.1rem;
      color: var(--color-white);
    }
    &-3 {
      font-weight: 400;
      font-size: 1.7rem;
      line-height: clamp(2.8rem, 3vw, 3.1rem);
      color: #06032fb5; // var(--color-3);
      font-family: "Inter", Helvetica, Arial, serif;
      letter-spacing: -0.03062rem;
    }
    &-4 {
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 160%;
      color: var(--color-white);
    }
    &-5 {
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: var(--color-white);
    }
    &-6 {
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 160%;
      color: var(--color-white);
    }
    &-7 {
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 160%;
      color: var(--color-3);
    }
  }
`;
