import styled from "styled-components";
import { responsive } from "styles/Abstract/Breakpoints";
import { FlexRowJcBetweenAiCenter } from "styles/Abstract/Mixins";
import { Center } from "styles/layouts/Center";

export const Wrapper = styled.section`
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: var(--color-primary);
`;

export const Container = styled(Center)`
  ${FlexRowJcBetweenAiCenter};
  gap: 3rem;

  ${responsive(
    "sm",
    `
      flex-direction: column;
      align-items: flex-start;
    `
  )}
`;
