import styled from "styled-components";

export const LinkElement = styled.span`
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: var(--color-1);
  text-decoration: none;
  transition: 0.3s;
  position: relative;
  padding-bottom: 0.6rem;

  &::after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0.6rem;
    width: 100%;
    height: 0;
    background-color: var(--color-primary);
    transition: 0.2s;
  }

  &:hover {
    color: var(--color-primary);
    &::after {
      height: 0.2rem;
      bottom: 0;
    }
  }
`;
