import React, { useState, useEffect } from "react";
import { Button } from "Components/Atoms/Button";
import Typography from "Components/Atoms/Typography";
import { Flex } from "styles/layouts/Flex";
import { Wrapper, Container } from "./style";
import AnimateText from "./AnimatedText";
import { Link } from "react-router-dom";

interface HeroProps {}

const Hero: React.FC<HeroProps> = () => {
  // State
  const [active, setActive] = useState(0);

  // Var
  const slides = [
    <AnimateText key="1" label="Retail" />,
    <AnimateText key="2" label="Distribution" />,
    <AnimateText key="3" label="Fufilment" />,
  ];

  // Efects
  useEffect(() => {
    if (slides.length === 1) return;
    const slideToggle = setInterval(() => {
      setActive((state) => {
        if (state === slides.length - 1) return 0;
        return state + 1;
      });
    }, 3000);

    return () => {
      clearInterval(slideToggle);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Wrapper>
      <Container>
        <div>
          <div className="vv">
            <Typography as="h3" className="heading-1 fg">
              Grow Africa’s
              <div className="vb">
                {/* <AnimatePresence initial={true}> */}
                {slides[active]}
                {/* </AnimatePresence> */}
              </div>
            </Typography>
          </div>
          <Typography as="h3" className="heading-1 header-responsive-text mb-25">
            Potential.
          </Typography>
          <Typography as="p" className="paragraph-1 max-width-830">
            We provide an ecosystem that serves as a launchpad for our partners
            (customers), enabling them to operate and scale their businesses in
            today's dynamic and technology-driven environment.
          </Typography>

          <Link to="/about-us">
            <Button className="btn-primary mt-50" label="Learn more" />
          </Link>

          <Flex gap="4.2rem" className="mt-50" flexRowAiCenter>
            <Typography as="h5" className="heading-2 max-width-650 ">
              <span className="heading-2--sub">Active Users</span> <br />
              <br /> <span className="custom-box-border">4500+</span>
            </Typography>
            <Typography as="h5" className="heading-2 max-width-650 ">
              <span className="heading-2--sub">Total Beneficiaries</span> <br />
              <br /><span className="custom-box-border">1M+</span>
            </Typography>
          </Flex>
        </div>
        <div>
          <img
            src="/assets/images/main-banner.jpeg"
            className="responsive-image"
            alt=""
          />
        </div>
      </Container>
    </Wrapper>
  );
};

export default Hero;
