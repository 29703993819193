import { motion } from "framer-motion";
import styled from "styled-components";
import { responsive } from "styles/Abstract/Breakpoints";
import { FlexRowJcBetweenAiCenter } from "styles/Abstract/Mixins";
import { Center } from "styles/layouts/Center";

export const Wrapper = styled.header`
  padding-top: 6rem;
`;

export const Container = styled(Center)`
  ${FlexRowJcBetweenAiCenter};
  /* gap: 1rem; */

  .vv {
    /* height: 7rem;
    overflow: hidden;
    width: 100%; */
  }

  .fg {
    display: flex;
    gap: 0.6rem;
  }

  .vb {
    display: flex;
    flex-direction: column;
    height: 7rem;
    overflow: hidden;
  }

  .custom-box-border {
    border-radius: 5px;
    border: 2px solid #06032f80;
    width: 100px;
    text-align: center;
    padding: 10px;
  }

  ${responsive(
    "xmd",
    `
      flex-direction: column;
      gap: 10rem;
      align-items: flex-start;
    `
  )}
`;

export const AnimatedText = styled(motion.h3)`
  /* overflow: hidden; */
`;
