import styled from "styled-components";
import { responsive } from "styles/Abstract/Breakpoints";
import { FlexRowJcCenterAiCenter } from "styles/Abstract/Mixins";
import { Center } from "styles/layouts/Center";

export const Wrapper = styled.section`
  padding-top: 0rem;
  padding-bottom: 10rem;
`;

export const Container = styled(Center)`
  background-image: url("/assets/images/bg-img.svg");
  background-size: cover;
  background-position: bottom right;
  background-repeat: no-repeat;
  /* margin-right: 6rem;
  margin-left: 6rem; */

  border-radius: 1rem;
  overflow: hidden;
  padding: 8rem;
  ${FlexRowJcCenterAiCenter};
  gap: 10rem;

  .left {
    flex-basis: 44rem;

    ${responsive(
      "sm",
      `
      flex-basis: auto;
    `
    )}
  }
  .right {
    flex-basis: 49rem;

    ${responsive(
      "sm",
      `
      flex-basis: auto;
    `
    )}
  }

  .user {
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    flex-shrink: 0;

    ${responsive(
      "sm",
      `
       width: 7rem;
      height: 7rem;
    `
    )}
  }

  .custom-font {
    font-family: "Athelas", Helvetica, Arial, serif;
  }

  .rounded-border {
    border-radius: 50%;
  }

  ${responsive(
    "sm",
    `
      flex-direction: column;
      gap: 4rem;
      padding: 4rem;
    `
  )}
  ${responsive(
    "xsm",
    `
      padding: 2.5rem ;
    `
  )}
`;
