import styled from "styled-components";
import { responsive } from "styles/Abstract/Breakpoints";

const Wrapper = styled.nav`
  padding-top: 4rem;
  padding-bottom: 4rem;

  .nav-links {
    ${responsive(
      "sm",
      `
      display: none;
    `
    )}
  }
`;

export default Wrapper;
