import styled from "styled-components";
import { responsive } from "styles/Abstract/Breakpoints";

const Wrapper = styled.div`
  position: relative;
  width: 47rem;
  height: 50rem;
  background-color: grey;
  overflow: hidden;

  .details {
    padding: 2rem;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: var(--color-4);
    transform: translateY(100%);
    transition: 0.4s;
  }

  &:hover {
    .details {
      transform: translateY(0);
    }
  }

  ${responsive(
    "sm",
    `
    height: 40rem;
    width: 100%;
  `
  )}
`;

export default Wrapper;
