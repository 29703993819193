import React from "react";
import { Link } from "react-router-dom";
import { LinkElement } from "./style";

interface NavLinkProps {
  href: string;
  label: string;
}
const NavLink: React.FC<NavLinkProps> = ({ href, label }) => {
  return (
    <Link to={href} className="text-decoration-none">
      <LinkElement>{label}</LinkElement>
    </Link>
  );
};

export default NavLink;
