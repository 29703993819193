import styled from "styled-components";
import { Button } from "styles/Components/Button";

export const ButtonStyle = styled(Button)`
  &.btn {
    &-primary {
      background: var(--color-primary);
      border-radius: 0.5rem;
      color: var(--color-white);
      padding: 1.6rem 4.64rem;

      font-weight: 700;
      font-size: 1.6rem;
    }

    &-dark {
      background: var(--color-black);
    }

    /* Sizes */
    &-md {
      font-weight: 700;
      font-size: 1.6rem;
      padding: 1rem 2rem;
    }
    &-sm {
      font-weight: 400;
      font-size: 1.6rem;
      padding: 0.5rem 1.5rem;
    }
  }
`;
