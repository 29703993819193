import React from "react";
import TopNav from "Components/Molecules/TopNav";
import SubFooter from "Components/Organisms/SubFooter";
import Footer from "Components/Organisms/Footer";
import Header from "Components/Molecules/Header";
import { Center } from "styles/layouts/Center";
import { Flex } from "styles/layouts/Flex";
import Typography from "Components/Atoms/Typography";
import { Wrapper } from "./style";

interface HomeProps {}

const OurServicesTemplate: React.FC<HomeProps> = () => {
  return (
    <Wrapper>
      <TopNav />
      <Header
        title={"Our Services"}
        subTitle={"Delivering The Future of Customer Experience Today"}
      />
      <section className="section-description">
        <Center>
          <Flex gap="7rem" className="layout" flexRowJcBetweenAiCenter>
            <div className="layout-image">
              <img
                src="/assets/images/saas.jpeg"
                alt=""
                height="430"
                className="responsive-image"
              />
            </div>
            <div>
              <Typography as="h3" className="heading-7 max-width-650 mb-25">
                Software as a service (SAAS)
              </Typography>
              <Typography
                as="p"
                className="paragraph-1 services-text max-width-490"
              >
                Managing a smooth business operation can be stressful, so why
                not let us help you out.
              </Typography>
              <Typography
                as="p"
                className="paragraph-1 services-text max-width-490"
              >
                24seven provides you with one of the best software tailored to
                your operations. It is an all-in-one stock management system,
                integrated social commerce solution and much more. join us and
                experience simplicity itself
              </Typography>
            </div>
          </Flex>
          <br />
          <br />
          <Flex gap="3rem" className="layout" flexRowJcBetweenAiCenter>
            <div>
              <Typography as="h3" className="heading-7 max-width-650 mb-25">
                Business Advisory
              </Typography>
              <Typography
                as="p"
                className="paragraph-1 services-text max-width-490"
              >
                We provide tools, advisory and support to better use of data in
                making business decisions (inventory planning and management,
                forecasting, basic accounting and book-Keeping{" "}
              </Typography>
            </div>
            <div className="layout-image change-order">
              <img
                src="/assets/images/shutterstock_739423216 1.jpeg"
                alt=""
                height="430"
                className="responsive-image"
              />
            </div>
          </Flex>
          <br />
          <br />
          <Flex gap="10rem" className="layout" flexRowJcBetweenAiCenter>
            <div className="layout-image">
              <img
                src="/assets/images/shutterstock_1941473266 1.jpeg"
                alt=""
                height="430"
                className="responsive-image"
              />
            </div>
            <div>
              <Typography as="h3" className="heading-7 max-width-650 mb-25">
                Advertisement, Graphics & Content
              </Typography>
              <Typography
                as="p"
                className="paragraph-1 services-text max-width-490"
              >
                An in-house marketing solution , in-platform to increase
                consumer goods brand loyalty & reach coupled with graphics &
                content teams to ensure your marketing campaigns , store designs
                and product content are constantly refreshed to maximize
                engagement and sales{" "}
              </Typography>
            </div>
          </Flex>
        </Center>
      </section>
      <SubFooter />
      <Footer />
    </Wrapper>
  );
};

export default OurServicesTemplate;
