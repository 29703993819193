import React from "react";

import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Home from "Components/Pages/Home";
import AboutUs from "Components/Pages/AboutUs";
import OurServices from "Components/Pages/OurServices";
import ContactUs from "Components/Pages/ContactUs";

interface Props {}
const Router: React.FC<Props> = () => {
  return (
    <BrowserRouter>
      <AnimatedRoutes />
    </BrowserRouter>
  );
};

export default Router;

interface AnimatedProps {}
const AnimatedRoutes: React.FC<AnimatedProps> = () => {
  const location = useLocation();
  return (
    <AnimatePresence exitBeforeEnter>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />}>
          <Route path=":path" element={"Home"} />
        </Route>
        <Route path="about-us" element={<AboutUs />}>
          <Route path=":id" element={"detail"} />
        </Route>
        <Route path="our-services" element={<OurServices />}>
          <Route path=":id" element={"detail"} />
        </Route>
        <Route path="contact-us" element={<ContactUs />}>
          <Route path=":id" element={"detail"} />
        </Route>
      </Routes>
    </AnimatePresence>
  );
};
