import styled from "styled-components";
import { responsive } from "styles/Abstract/Breakpoints";
import { FlexRowJcBetweenAiEnd } from "styles/Abstract/Mixins";
import { Center } from "styles/layouts/Center";

export const Wrapper = styled.footer`
  background-color: var(--color-4);
  padding-top: 4rem;
  padding-bottom: 4rem;
`;

export const Container = styled(Center)`
  ${FlexRowJcBetweenAiEnd}

  ${responsive(
    "sm",
    `
      flex-direction: column;
      align-items: flex-start;
      gap: 5rem;
    `
  )}
`;
