import React from "react";
import HomeTemplate from "Components/Templates/Home";

interface HomeProps {}
const Home: React.FC<HomeProps> = () => {
  return (
    <>
      <HomeTemplate />
    </>
  );
};

export default Home;
