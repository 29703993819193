import styled from "styled-components";
import { responsive } from "styles/Abstract/Breakpoints";
import {
  FlexColumnJcBetween,
  FlexRowAiCenter,
  FlexRowJcBetween,
} from "styles/Abstract/Mixins";
import { MoveXAxisFromLeft } from "styles/Base/Animation";
import { Center } from "styles/layouts/Center";

export const Wrapper = styled(Center)`
  .custom-box-border {
    border-radius: 10px;
    border: 2px solid #efb61d;
    width: 100%;
    padding: 30px;
    font-family: "Inter", Athelas, Helvetica, Arial, serif;
  }
`;

export const WhatDiffSection = styled.section`
  padding-top: 10rem;
  padding-bottom: 10rem;
`;

export const WhatDiffContainer = styled(Center)`
  .content {
    ${FlexRowJcBetween};
    gap: 7rem;

    .left {
      & > * {
        border-bottom: 1px solid var(--color-3);
        padding-top: 3rem;
        padding-bottom: 1rem;
      }
    }

    .right {
      flex-basis: 50rem;

      ${responsive(
        "sm",
        `
      flex-basis: auto;
    `
      )}
    }

    ${responsive(
      "sm",
      `
      flex-direction: column;
      align-items: flex-start;
    `
    )}
  }
`;

export const WhatWeOfferSection = styled.section`
  padding-top: 7rem;
  padding-bottom: 7rem;
`;
export const WhatWeOfferContainer = styled(Center)`
  ${FlexRowAiCenter}

  .slider {
    flex-grow: 1;
    overflow-x: hidden;

    ${responsive(
      "sm",
      `
     width: 100%;
      
    `
    )}
  }

  .slider-scroll {
    width: 100%;
    overflow-x: auto;
    animation: ${MoveXAxisFromLeft} 9s linear infinite alternate both;
    scrollbar-width: none;
  }
  .services {
    ${FlexRowAiCenter};
    gap: 1rem;
    /* width: 100%; */

    .slick-track {
      display: flex;
      gap: 1rem;
      flex-grow: 0;

      & > * {
        width: 31.2rem !important;
        align-items: center;
        flex-shrink: 0;

        &.center {
          padding: 5rem 4rem;
        }
      }
    }

    .slick-next,
    .slick-prev {
      opacity: 0;
    }
  }
  .service {
    ${FlexColumnJcBetween};

    gap: 3rem;
    background-color: var(--color-7);
    padding: 3rem 2rem;
    width: 31.2rem;
    flex-shrink: 0;

    &.active {
      padding: 5rem 4rem;
    }

    .link {
      text-decoration: none;
      font-weight: 500;
      font-size: 16px;
      line-height: 36px;
      color: var(--color-8);
      margin-top: 1.5rem;
      display: inline-block;
    }
  }

  ${responsive(
    "sm",
    `
      flex-direction: column;
      gap: 5rem;
      
    `
  )}
`;
export const WhatWeOfferLayout = styled.section``;
