import Typography from "Components/Atoms/Typography";
import { Center } from "styles/layouts/Center";
import { Wrapper, Container, SlideWrapper } from "./style";

interface BrandsProps {}

const Brands: React.FC<BrandsProps> = () => {
  return (
    <Wrapper>
      <Center>
        <div>
          <Typography as="p" className="paragraph-3 max-width-700 mt-20">
            OUR TRUSTED BRANDS
          </Typography>
        </div>
      </Center>
      <SlideWrapper>
        <Container>
          <img src="/assets/images/pic-2.svg" alt="" />
          <img src="/assets/images/pic-3.svg" alt="" />
          {/* <img src="/assets/images/pic-4.svg" alt="" /> */}
          <img src="/assets/images/pic-5.svg" alt="" />
          <img src="/assets/images/pic-6.svg" alt="" />
          <img src="/assets/images/pic-12.svg" alt="" />
        </Container>
      </SlideWrapper>
    </Wrapper>
  );
};

export default Brands;
